.Header_wrapper{
    width:100%;
    background-color: royalblue;
    height:5rem;
    display: flex;
    align-items: center;
    @media (max-width: 500px){
        justify-content: center;   
    }
    .Header_title{
        margin-left: 2rem;  
        font-size: 1.5rem;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; 
        color:white; 
        text-decoration: none;
        @media (max-width: 500px){
            margin-left: 0;  
            text-align: center;  
            font-size: 1.2rem;
        }
    }
}