.StudentShow_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:calc(100vh - 7.5rem);
    background-color: rgb(240, 241, 243);

    .student-table{
        border-collapse: collapse;
        margin-top: 5rem;
        overflow-y: scroll;
        .student-table-head{
            background-color: rgb(72, 79, 85);
            color:white;
            font-family: 'Courier New', Courier, monospace;
            th{
                padding:0.7rem 1rem; 
                @media (max-width: 462px){
                    padding:0;
                }
            }
        }
        .student-table-body{
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            background-color: white;
          
            td{
                padding:0.7rem 1rem;
                @media (max-width: 462px){
                    padding:0 0 0 0.3rem;
                }
            }
            .student-table-buttons{
                  display: flex;
                  flex-direction: row;
                @media (max-width: 462px){
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .student-table-button-detail{
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color:black;
                    background-color: royalblue;
                    height:2rem;
                    width:3.5rem;
                    border:1px solid rgb(111, 193, 130);
                    @media (max-width: 462px){
                        width:3rem;
                        height:2.5rem;
                    }
                    &:hover{
                        color:royalblue;
                        border:1px solid royalblue;
                        background-color: white;
                        
                    }
            }
            .student-table-button-edit{
                text-decoration: none;
                display: flex;
                color:black;
                align-items: center;
                justify-content: center;
                background-color: rgb(111, 193, 130);
                height:2rem;
                width:3.5rem;
                border:1px solid rgb(111, 193, 130);
                @media (max-width: 462px){
                    width:3rem;
                    height:2.5rem;
                }
                &:hover{
                    color:rgb(111, 193, 130);
                    border:1px solid rgb(111, 193, 130);
                    background-color: white;
                }
            }
            .student-table-button-delete{
                background-color: red;
                 display: flex;
                align-items: center;
                justify-content: center;
                height:2.15rem;
                width:3.5rem;
                border:1px solid red;
                @media (max-width: 462px){
                    width:3rem;
                    height:2.5rem;
                }
                &:hover{
                    color:red;
                    border:1px solid red;
                    background-color: white;
                }
            }
            }
            
        }
    }
}

.list_button_add{
     height:2rem;
     width:3rem;
     border:1px solid royalblue;
     margin-top: 2rem;
     background-color: royalblue;
     display: flex;
     align-items: center;
     justify-content: center;
     text-decoration: none;
     font-family: 'Courier New', Courier, monospace;
     color:white;
     &:hover{
         color:royalblue;
         background-color: white;
     }
}