.Footer_wrapper{
    width:100%;
    background-color: royalblue;
    height:2.5rem;
    display: flex;
    align-items: center;
    background-color: rgb(40, 40, 40);
    justify-content: center;   
    position:fixed;
    left:0;
    bottom: 0;
    .Footer_title{
        font-size: 1rem;
        font-family: 'Courier New', Courier, monospace;
        color:white; 
    }
}